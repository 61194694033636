import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Inicio',
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: 'Sobre mí',
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      title: 'Contacto',
    }
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import('../views/Resources.vue'),
    meta: {
      title: 'Recursos',
    }
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to #main except when going to "/"
    return { 
      el: to.name === 'Home' ? '#top' : '#main',
      behavior: 'smooth',
    }
  }
})

router.beforeEach((to, from, next) => {
  // to and from are both route objects. must call `next`.
  let documentTitle = `${to.meta.title} - ${process.env.VUE_APP_TITLE}`
  if (to.params.title) {
    documentTitle = `${to.params.title} - ${process.env.VUE_APP_TITLE}`
  }
  document.title = documentTitle
  next()
})

export default router
