<template>
    <section class="skeleton__container container">
        <div class="skeleton__block"></div>
        <div class="skeleton__block"></div>
        <div class="skeleton__block"></div>
        <div class="skeleton__block"></div>
        <div class="skeleton__block"></div>
        <div class="skeleton__block"></div>
    </section>
</template>
<style lang="sass" scoped>
@use '@/style/colors'
@use '@/style/mixins'
@use '@/style/animations'
@use '@/style/variables'

.skeleton__container
    margin-top: 2rem
    @include mixins.grid(3, 1, center, center)
    grid-gap: 1rem
    width: 95%
    @media (max-width: variables.$bkp-large)
        grid-template-columns: repeat(2, 1fr)
    @media (max-width: variables.$bkp-small)
        grid-template-columns: repeat(1, 1fr)
.skeleton__block
    width: 100%
    height: auto
    aspect-ratio: 1
    background: linear-gradient(270deg, colors.getColor("background-shadow-1"), colors.getColor("background-shadow-2"))
    background-size: 400% 400%
    animation: skeleton-loading 2s ease infinite
    border-radius: 10px
</style>