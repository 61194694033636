<template>
	<svg viewBox="0 0 24 24">
		<path
			fill="currentColor"
			d="M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14M7 18v-2H5v2h2m0-5v-2H5v2h2m0-5V6H5v2h2m12 10v-2h-2v2h2m0-5v-2h-2v2h2m0-5V6h-2v2h2Z"
		></path>
	</svg>
</template>

<script>
export default {
	name: "Filmstrip",
};
</script>
