<script setup>
import { defineAsyncComponent, ref } from 'vue';
import OpenNewWindowVue from '@/assets/icons/OpenNewWindow.vue';
import { useDatabaseStore } from "@/store/database.js"
const WebDesignModal = defineAsyncComponent(() => import('@/components/WebDesignModal.vue'));

const query = `*[_type == "webdesign-collection"] {
  _id,
  title,
  description,
  thumbnail,
  websiteLink,
  tech,
}`

const db = useDatabaseStore();
await db.getWebsites(query)


const webDataModal = ref({});
const showWebDesignModal = ref(false);

const toggleWebDesignModal = () => {
    showWebDesignModal.value = !showWebDesignModal.value
    if (showWebDesignModal.value === true ) {
        document.body.setAttribute("id", "modal-open")
    } else {
        document.body.removeAttribute("id", "modal-open")
    }
}
const openWebDesignModal = (website) => {
    toggleWebDesignModal();
    webDataModal.value = website;
}
const closeWebDesignModal = () => {
    toggleWebDesignModal();
}

</script>

<template>
    <div class="webdesign-container container">
        <!-- Modal -->
        <WebDesignModal 
            v-if="showWebDesignModal"
            :webData="webDataModal"
            @closeWebDesignModal="closeWebDesignModal"
        />

        <div class="webdesign-wrapper">
            <!-- Website Card -->
            <div v-for="website in db.websites" :key="website._id" class="webdesign-item">
                <span @click="openWebDesignModal(website)">
                    <ix-img
                        :src="website.thumbnail"
                        loading="lazy"
                        sizes="624px"
                        :alt="website.title"
                    />
                    <h3 class="webdesign__title">{{ website.title }}</h3>
                </span>
                <a :href="website.websiteLink" target="_blank">
                    <OpenNewWindowVue class="icon-hyperlink" />
                </a> 
            </div>
        </div>
    </div>
</template>


<style lang="sass" scoped>
@use '@/style/animations'
@use '@/style/colors'
@use '@/style/mixins'
@use '@/style/variables'
@use '@/style/fonts'

.webdesign-wrapper
    @include mixins.flex(row, flex-start, flex-start, wrap)
    gap: 2rem 0
    margin-top: 2rem
    margin-inline: auto
    @media screen and (max-width: variables.$bkp-large)
        justify-content: center
        .webdesign-item__wrapper
            width: 80vw
.webdesign-item
    background-color: colors.getColor("background")
    width: 100%
    height: auto
    aspect-ratio: 4/3
    border-radius: 10px
    overflow: hidden
    max-width: 624px
    max-height: 474px
    position: relative
    @media screen and (min-width: variables.$bkp-large)
        width: calc(50% - 1rem)
        &:nth-child(odd)
            margin-right: 2rem
    img
        width: 100%
        height: 100%
        display: block
        z-index: variables.$z-content
        cursor: pointer
        object-fit: cover
        object-position: center 0
        background-color: colors.getColor("background-light-2")
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px
        transition: all 0.3s ease-in-out
    &:hover img
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
        filter: brightness(0.7)
    &:hover
        .webdesign__title,
        .icon-hyperlink
            visibility: visible
            opacity: 1
        .webdesign__title
            -webkit-animation: slide-in-bottom 0.3s ease-in-out both
            animation: slide-in-bottom 0.3s ease-in-out both
    .webdesign__title
        visibility: hidden
        color: #fff
        position: absolute
        opacity: 0
        bottom: 30px
        left: 20px
        z-index: variables.$z-content-above
        transition: all 0.3s ease-in-out
        cursor: pointer
        font-size: fonts.$font-lg
    .icon-hyperlink
        position: absolute
        top: 15px
        right: 15px
        width: 30px
        height: 30px
        stroke-width: 2
        opacity: 0
        visibility: hidden
        transition: all 0.2s ease-in-out
        color: colors.$white
        &:hover
            filter: drop-shadow(rgba(0, 0, 0, 1) 0px 10px 15px)

</style>