<script setup>
import { useDatabaseStore } from "@/store/database.js"
import { ref, computed, defineAsyncComponent } from "vue"

// Icons
import PlayArrow from "@/assets/icons/PlayArrow.vue"
import Calendar from "@/assets/icons/Calendar.vue"
import Filmstrip from "@/assets/icons/Filmstrip.vue"

const VideoModal = defineAsyncComponent(() => import ("@/components/VideoModal.vue"))

const query = `*[_type == "video-collection"] {
  _id,
  videoSource,
  videoURL,
  title,
  tag,
  date,
  description,
}`

const db = useDatabaseStore();
await db.getCinematography(query)


const getThumbnail = (video) => {
    if (video.videoSource === 'youtube') {
        return "https://img.youtube.com/vi/" + video.videoURL + "/maxresdefault.jpg"
    } else if (video.videoSource === 'vimeo') {
        return "https://vumbnail.com/" + video.videoURL + ".jpg"
    } else {
        return "https://via.placeholder.com/600x400"
    }
}

const showVideoModal = ref(false);
const videoModalURL = ref("");
const videoModalSource = ref("");
const videoModalTitle = ref("");

const toggleVideoModal = (video) => {
    showVideoModal.value = !showVideoModal.value
    videoModalURL.value = video.videoURL;
    videoModalTitle.value = video.title;
    videoModalSource.value = video.videoSource;
}

const bodyOverflow = computed(() => {
    if(showVideoModal.value) {
      return document.querySelector('body').style.overflow = 'hidden'
    } else {
      return document.querySelector('body').style.overflow = 'overlay'
    }
})

</script>


<template>
    <div class="cinematography-container container">

    
    <VideoModal
        v-if="showVideoModal"
        :videoURL="videoModalURL"
        :videoSource="videoModalSource"
        :videoTitle="videoModalTitle"
        @closeVideoModal="showVideoModal = false"
    />

        <div v-for="video in db.videos" class="cine-block" @click="toggleVideoModal(video)" :key="video._id">
            <div class="video-thumbnail">
                <img :src="getThumbnail(video)" loading="lazy" :alt="video.title">
                <PlayArrow class="play-arrow"/>
                <span class="body-overflow">{{ bodyOverflow }}</span>
            </div>
            <div class="video-description">
                <h3 class="video-title">{{ video.title }}</h3>
                <div class="video-details">
                    <Filmstrip />
                    <p class="video-type"> {{ video.tag }}</p>
                    <Calendar /> 
                    <p class="video-date">{{ video.date }}</p>
                </div>
                <p>{{ video.description }}</p>
            </div>
        </div>

    </div>
</template>

<style lang="sass" scoped>
@use '@/style/colors'
@use '@/style/fonts'
@use '@/style/mixins'
@use '@/style/variables'
.cinematography-container
    @include mixins.flex(row, center, stretch, wrap)
    gap: 2rem 0
    margin-top: 2rem
    margin-inline: auto
    .cine-block
        width: calc(50% - 2rem)
    @media screen and (max-width: 1350px)
        max-width: 95%
    @media screen and (max-width: variables.$bkp-medium)
        flex-direction: column
        margin-inline: auto
        .cine-block
            width: 100%
    @media screen and (min-width: calc(variables.$bkp-medium + 1px))
        .cine-block:nth-child(odd)
            margin-right: 1rem
        .cine-block:nth-child(even)
            margin-left: 1rem
.cine-block
    border-radius: 10px
    background-color: colors.getColor("background")
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2)
    cursor: pointer
    text-align: left
    transition: box-shadow variables.$transition-fast
    .body-overflow
        display: none
    .video-thumbnail
        position: relative
        img
            border-radius: 10px 10px 0 0
            transition: filter variables.$transition-fast
            width: 100%
            display: block
            aspect-ratio: 231/130
        .play-arrow
            color: colors.$light
            position: absolute
            width: 4rem
            height: 4rem
            top: calc(50% - 2rem)
            left: calc(50% - 2rem)
            z-index: 2
            filter: drop-shadow(0 0.4rem 0.4rem rgba(0, 0, 0, 0.4))
            opacity: 0
            transition: all variables.$transition-fast
    &:hover
        box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.4)
        .video-thumbnail
            img
                filter: brightness(0.7)
            .play-arrow
                opacity: 1
    .video-description
        padding: 1rem
        color: colors.getColor("text")
        .video-title
            font-size: fonts.$font-md
            font-weight: fonts.$font-semibold
            margin-bottom: 0.5rem
        .video-details
            @include mixins.flex(row, flex-start, center, nowrap)
            font-weight: fonts.$font-bold
            margin-bottom: 2rem
            font-size: fonts.$font-sm
            letter-spacing: 0.05rem
            p
                margin-inline: 0.2rem 1rem
            svg
                width: 1.2rem
                    
            
</style>