<script setup>
import { useDatabaseStore } from "@/store/database";
import Cover from "./components/Cover.vue";
import Footer from "./components/Footer.vue";
import LoadingAnimation from "@/components/LoadingAnimation.vue";

// TODO: Create load animation until heroku db is fetched
const database = useDatabaseStore();
</script>

<template>
	<LoadingAnimation v-if="database.isLoading" />
	<Cover id="top" />
	<router-view v-slot="{ Component }">
		<transition name="fade" mode="out-in">
			<component id="main" :is="Component" :key="$route.path" />
		</transition>
	</router-view>
	<Footer />
</template>

<style lang="sass">
@use "@/style/fonts"
@use "@/style/links"
@use "@/style/colors"
*
    box-sizing: border-box
    margin: 0
    padding: 0
#app
    font-family: 'General Sans', sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    text-align: center
    line-height: 1.5
body
    background-color: colors.getColor("white")
    color: colors.getColor("text")
    overflow: overlay
.container
    max-width: 1280px
    width: 95%
    margin-inline: auto

img
    max-width: 100%
h1
    font-size: fonts.$font-xxxl
h2
    font-size: fonts.$font-xxl
h3
    font-size: fonts.$font-xl
h4
    font-size: fonts.$font-lg
h5
    font-size: fonts.$font-base
h6
    font-size: fonts.$font-sm
p, a
    font-size: fonts.$font-base
//  Scrollbar
*
    scrollbar-width: auto
    scrollbar-color: colors.getColor("background")
*::-webkit-scrollbar
    width: 0.7rem
    background: transparent
*::-webkit-scrollbar-thumb
    background-color: colors.getColor("text-light-3")
    border-radius: 10px
    &:hover
        background-color: colors.getColor("text-light-2")


//   When the modal is open, the body isn't scrollable.
//   This style is marked as !important because otherwise it is nullified by the default element style

#modal-open
    overflow: hidden !important



.markdown-text
    line-height: 1.7
    text-align: left
    *
        margin-block: 1.5rem
    p, a, span
        font-size: fonts.$font-md
    a
        color: colors.$accent-light
        text-decoration: underline
    br
        display: block
        content: ""
        margin-top: 0.7em
        line-height: 200%
        vertical-align: top
    h1, h2, h3, h4, h5, h6
        margin-block: 2rem 1.5rem
    li
        margin-block: 0.5rem
    img
        width: 100%
        max-width: 1280px
        max-height: 700px
        display: block
        height: auto
        margin-inline: auto
        object-fit: cover
        object-position: center

.fade-enter-active,
.fade-leave-active
    transition: opacity .3s
.fade-enter-from,
.fade-leave-to
    opacity: 0
</style>
