<script setup>
import { useDatabaseStore } from '@/store/database.js'

const db = useDatabaseStore();
if (Object.keys(db.randomPhoto).length === 0) {
    await db.getRandomPhoto();
}
</script>

<template>

    <ix-img
        class="cover__background--image"
        :src="db.randomPhoto.source"
        :data-source="'https://simonettitomas.imgix.net/' + db.randomPhoto.source"
        alt="Cover background image"
        sizes="100vw"
    />

</template>

<style lang="sass" scoped>

.cover__background--image
    width: 100%
    height: 100%
    object-fit: cover

</style>