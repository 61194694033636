<script setup>
import { ref } from "@vue/reactivity";
import Photography from "@/components/Photography.vue";
import Cinematography from "@/components/Cinematography.vue";
import WebDesign from "@/components/WebDesign.vue";
import ScrollTo from "@/components/ScrollTo.vue"
// Loading Skeletons
import PhotographySkeleton from "@/components/PhotographySkeleton.vue";
import CineAndWebSkeleton from "@/components/CineAndWebSkeleton.vue";
// Icons
import PhotographyIcon from "@/assets/icons/PhotographyIcon.vue";
import WebDesignIcon from "@/assets/icons/WebDesignIcon.vue";
import CinematographyIcon from "@/assets/icons/CinematographyIcon.vue";

const currentPage = ref("Photography");
const homePages = {
	Photography,
	Cinematography,
	WebDesign,
};

const togglePage = (page) => {
	currentPage.value = page;
};
const homePagesSkeletons = {
	Photography: PhotographySkeleton,
	Cinematography: CineAndWebSkeleton,
	WebDesign: CineAndWebSkeleton,
};

const scrollTo = (element) => {
    document.querySelector(element).scrollIntoView({ behavior: 'smooth' })
}
</script>

<template>
	<main>
		<div class="home-nav">
                <div class="home-nav__container">
                    <button
                        @click="togglePage('Cinematography'), scrollTo('#main')"
                        aria-label="Ver Cinematografías"
                        :class="{ currentPage: currentPage === 'Cinematography' }"
                        class="link home-nav__button"
                    >
                        <CinematographyIcon class="home-nav__icon" />
                        <span class="home-nav__name">Cinematografía</span>
                    </button>
                    <button
                        @click="togglePage('Photography'), scrollTo('#main')"
                        aria-label="Ver Fotografías"
                        :class="{ currentPage: currentPage === 'Photography' }"
                        class="link home-nav__button"
                    >
                        <PhotographyIcon class="home-nav__icon" />
                        <span class="home-nav__name">Fotografía</span>
                    </button>
                    <button
                        @click="togglePage('WebDesign'), scrollTo('#main')"
                        aria-label="Ver Diseño web"
                        :class="{ currentPage: currentPage === 'WebDesign' }"
                        class="link home-nav__button"
                    >
                        <WebDesignIcon class="home-nav__icon" />
                        <span class="home-nav__name">Diseño web</span>
                    </button>
                </div>
		</div>
		<section class="home-content">
			<Suspense>
				<transition name="fade" mode="out-in">
					<KeepAlive>
						<component
							:is="homePages[currentPage]"
							:key="currentPage"
						/>
					</KeepAlive>
				</transition>
				<template #fallback>
					<component
						:is="homePagesSkeletons[currentPage]"
					></component>
				</template>
			</Suspense>
		</section>
        <section class="resources-reminder">
            <div class="resources-reminder__container">
                <h4 class="resources-reminder__title">Recursos</h4>
                <div>
                    <p class="resources-reminder__text">¿Tienes un proyecto multimedia en mente?</p>
                    <p class="resources-reminder__text">¡Mira las herramientas que tengo para ti!</p>
                </div>
                <RouterLink class="resources-reminder__btn" to="/resources">Dale un vistazo!</RouterLink>
            </div>
        </section>
	</main>
</template>

<style lang="sass" scoped>
@use '@/style/colors'
@use '@/style/fonts'
@use '@/style/mixins'
@use '@/style/variables'
.home-nav
    position: sticky
    top: 0
    z-index: variables.$z-content-above
    width: 100%
    //background-color: colors.getColor("white")
    .home-nav__container
        @include mixins.flex(row, center, center, nowrap)
        gap: 1rem
        padding: 20px
.home-nav__button
    @include mixins.flex(row, center, center, nowrap)
    gap: 0.5rem
    height: 60%
    font-size: fonts.$font-base
    font-weight: fonts.$font-semibold
    width: 12rem
    padding-block: 10px
    border: 0
    border-bottom: 5px solid transparent
    transition: border-bottom 0.2s ease-in-out
    background: linear-gradient(135deg, colors.getColor("background") 0%, colors.getColor("background-shadow-1") 100%)
    border-radius: 50px
    box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.4)
    .home-nav__icon
        width: 1.5rem
        height: 1.5rem
    .home-nav__name, .home-nav__icon
        color: colors.getColor("text")
    &:hover
        border-color: colors.getColor("accent")
.currentPage
    background: linear-gradient(45deg, colors.getColor("accent-light") 0%, colors.getColor("accent-dark") 100%)
    border-color: colors.getColor("accent-dark")

    /* Nav container Max 540px, Nav icons */
@media screen and (max-width: 540px)
    .home-nav .home-nav__container
        padding-top: 0
        gap: 0
        height: calc(25vw - 1rem)
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1)
        .home-nav__button
            flex-direction: column
            width: 100%
            height: calc(17vw - 1rem)
            background-color: colors.getColor("white")
            font-size: fonts.$font-md
            transition: background 0.2s ease-in-out, color 0.2s ease-in-out
            justify-content: center
            gap: 0
            &:hover
                border-color: transparent
                background-color: colors.getColor("background-shadow-1")
            .home-nav__name
                font-size: fonts.$font-base
                font-weight: fonts.$font-semibold
                @media screen and (max-width: 450px)
                    font-size: fonts.$font-sm
                @media screen and (max-width: 300px)
                    display: none
            .home-nav__icon
                height: variables.$icon-xxl
                width: variables.$icon-xxl
                max-height: 80%
                aspect-ratio: 1
        .currentPage
            background-color: colors.getColor("accent")
            .home-nav__name, .home-nav__icon
                color: colors.$white
            &:hover
                background-color: colors.getColor("accent")
                color: colors.getColor("background")
.home-content
    padding-bottom: 80px
    min-height: 100vh

.resources-reminder
    padding-block: 3rem
    position: relative
    &::after
        content: ""
        background-color: colors.getColor("background")
        display: block
        width: 100%
        height: 50%
        position: absolute
        bottom: 0
        left: 0
    &__container
        @include mixins.flex(row, space-around , center, nowrap)
        padding-block: 1rem
        position: relative
        z-index: variables.$z-content-above
        width: 1000px
        max-width: 90%
        margin: 0 auto
        background-color: colors.getColor("white")
        border-radius: 10px
        border: 3px solid colors.getColor("accent")
        container-type: inline-size
    &__text
        font-size: fonts.$font-sm
    &__btn
        display: inline-block
        margin-block: 1.5rem
        padding: 0.8rem 1.4rem
        border-radius: 5px
        color: colors.$white
        font-size: fonts.$font-base
        font-weight: fonts.$font-semibold
        border: 0
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px
        transition: all 0.2s ease-in-out
        cursor: pointer
        vertical-align: middle
        background-color: colors.getColor("accent")
        background-image: linear-gradient(45deg, colors.getColor("accent") 0%, #ff8b5f 100%)
        &:hover
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
            background-image: linear-gradient(45deg, colors.getColor("accent") 0%, #FF914A 100%)
            color: colors.$white
            margin-block: 1.3rem 1.7rem
    @media screen and (max-width: variables.$bkp-medium)
        padding-block: 1rem 2rem
        &__container
            flex-direction: column
            gap: 0.5rem
            padding-block: 1rem
        &__text
            font-size: fonts.$font-base
        &__btn
            width: 90%
            margin-block: 1rem
            &:hover
                margin-block: 0.8rem 1.2rem
// @media screen and (max-width: variables.$bkp-medium)

</style>
