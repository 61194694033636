import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { createPinia } from 'pinia'
const pinia = createPinia()

// External Libraries
    //? Viewer of full photography images
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
    //? Image delivery optimizer
import VueImgix from '@imgix/vue';


const app = createApp(App)
    .use(pinia)
    .use(router)
    .use(VueImgix, {
        domain: "simonettitomas.imgix.net",
        defaultIxParams: {
          // This enables the auto format imgix parameter by default for all images, which we recommend to reduce image size, but you might choose to turn this off.
          auto: 'format',
        },
    })
    .use(VueViewer)
    .mount('#app')