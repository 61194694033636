<template>
    <section class="skeleton__container container">
        <div class="skeleton__block"></div>
        <div class="skeleton__block"></div>
        <div class="skeleton__block"></div>
    </section>
</template>
<style lang="sass" scoped>
@use '@/style/colors'
@use '@/style/mixins'
@use '@/style/animations'
@use '@/style/variables'

.skeleton__container
    margin-top: 2rem
    @include mixins.grid(2, 1, center, center)
    grid-gap: 1rem
    width: 95%
    @media (max-width: variables.$bkp-medium)
        grid-template-columns: repeat(1, 1fr)
.skeleton__block
    position: relative
    width: 100%
    height: auto
    aspect-ratio: 4/3
    background: linear-gradient(270deg, colors.getColor("background-shadow-1"), colors.getColor("background-shadow-2"))
    background-size: 400% 400%
    animation: skeleton-loading 2s ease infinite
    border-radius: 10px
.skeleton__block::after
    content: ""
    display: block
    position: absolute
    width: 60%
    height: 1.5rem
    bottom: 1rem
    left: 1rem
    background: linear-gradient(270deg, colors.getColor("text-light-2"), colors.getColor("text"))
    background-size: 400% 400%
    animation: skeleton-loading 2s ease infinite
    border-radius: 50px
    opacity: 0.5
</style>