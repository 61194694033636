import { defineStore } from "pinia";
import sanity from "@/client";


export const useDatabaseStore = defineStore("database", {
    state: () => ({
        photos: [],
        websites: [],
        videos: [],
        resourcesNav: [],
        resourcesCollection: [],
        isLoading: false,
        randomPhoto: {},
    }),


    actions: {
        async getRandomPhoto() {
            if (this.photos.length === 0) {
                await this.getPhotos(`*[_type == "photo-gallery"] { source }`);
            }
            const randomPhoto = this.photos[Math.floor(Math.random() * this.photos.length)];
            return this.randomPhoto = randomPhoto;
        },
        async getPhotos(query) {
            await sanity.fetch(query).then(
                (data) => {
                    for (let item = data.length - 1; item > 0; item--) {
                        const j = Math.floor(Math.random() * (item + 1));
                        [data[item], data[j]] = [data[j], data[item]];
                    }
                    this.photos = data;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
        async getWebsites(query) {
            await sanity.fetch(query).then(
                (data) => {
                    this.websites = data;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
        async getCinematography(query) {
            await sanity.fetch(query).then(
                (data) => {
                    this.videos = data;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
        async getResourcesNav(query) {
            await sanity.fetch(query).then(
                (data) => {
                    this.resourcesNav = data;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
        async getResourcesCollection(query) {
            await sanity.fetch(query).then(
                (data) => {
                    for (let item = data.length - 1; item > 0; item--) {
                        const j = Math.floor(Math.random() * (item + 1));
                        [data[item], data[j]] = [data[j], data[item]];
                    }
                    this.resourcesCollection = data;
                },
                (error) => {
                    this.error = error;
                }
            );
        }
    }
});