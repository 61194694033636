<script setup>
import { useDatabaseStore } from "@/store/database.js"

const query = `*[_type == "photo-gallery"] {
  _id,
  title,
  source,
}`

const db = useDatabaseStore();
await db.getPhotos(query)

const options = { 
    "inline": false,
    "button": true,
    "navbar": true,
    "title": true,
    "toolbar": true,
    "tooltip": true,
    "movable": true,
    "zoomable": true,
    "rotatable": true,
    "scalable": true,
    "transition": true,
    "fullscreen": true,
    "keyboard": true,
    "url": "data-source"
}

// https://mirari.cc/vue3-viewer/
// https://github.com/mirari/v-viewer/blob/v3/example/views/example/ComponentExample.vue
// https://github.com/mirari/v-viewer/tree/v3
</script>

<template>
<div class="photography-container">
    <viewer
            ref="viewer"
            :options="options"
            :images="db.photos"
            class="viewer"
        >
        <div
            class="viewer-img"
            v-for="photo in db.photos"
            :key="photo._id"
        >
            <ix-img
                :src="photo.source"
                :alt="photo.title"
                :data-source="'https://simonettitomas.imgix.net/' + photo.source"
                loading="lazy"
                sizes="(min-width: 1025px) 33.3vw,
                        (min-width: 481px) 50vw,
                        100vw"
            />
        </div>
    </viewer>
</div>
</template>

<style lang="sass" scoped>
@use '@/style/variables'
.photography-container
    column-count: 3
    column-gap: 0
    column-fill: balance
    @media (max-width: variables.$bkp-large)
        column-count: 2
    @media (max-width: variables.$bkp-small)
        column-count: 1
.viewer
    transition: all 0.3s ease-in-out
    img
        cursor: pointer
        transition: all 0.3s ease-in-out
        display: block
        width: 100%
        &:hover
            filter: brightness(0.85)
            transform: scale(1.05)
    .viewer-img
        break-inside: avoid
        overflow: hidden
        width: 100%
</style>