<script setup>

import { useRoute } from 'vue-router'
import { onMounted, onUnmounted, computed, ref } from "vue"
import CoverImgBackground from './CoverImgBackground.vue'
import ScrollTo from "@/components/ScrollTo.vue"

// Icons
import OpenNewWindow from '@/assets/icons/OpenNewWindow.vue'
import MenuHamburger from '@/assets/icons/MenuHamburger.vue'
import Instagram from "@/assets/icons/Instagram.vue"
import Linkedin from "@/assets/icons/Linkedin.vue"
import MailOutline from "@/assets/icons/MailOutline.vue"
import ArrowDown from "@/assets/icons/ArrowDown.vue"


const currentRoute = computed(() => {
    return useRoute().meta.title
})

// Watches for window resize to display the desktop/mobile menu
const windowWidth = ref(window.innerWidth)
const onWidthChange = () => windowWidth.value = window.innerWidth
onMounted(() => window.addEventListener('resize', onWidthChange))
onUnmounted(() => window.removeEventListener('resize', onWidthChange))
const isMobile = computed(() => (windowWidth.value <= 750) ? true : false)

const mobileNav = ref(false)
const toggleMobileNav = () => mobileNav.value = !mobileNav.value
</script>

<template>
    <header>
        <div class="cover__background">
            <video v-if="!isMobile"
                class="cover__background--video"
                autoplay
                loop
                preload="metadata"
                muted
            >
                <source src="@/assets/reel-website.mp4" type="video/mp4">
                <source src="@/assets/reel-website.webm" type="video/webm">
            </video>
            <Suspense v-else-if="isMobile">
                <CoverImgBackground />
            </Suspense>

        </div>
        <div class="cover__content">
            <nav>
                <h2>{{ currentRoute }}</h2>
                <div class="content__right-content">
                    <!-- Desktop Nav -->
                    <div v-show="!isMobile" class="nav__links">
                        <ScrollTo :title="'Inicio'" href="#main"><router-link v-if="'Inicio' != currentRoute" to="/" class="link nav__link nav__link--first">Inicio</router-link></ScrollTo>
                        <router-link v-if="'Sobre mí' != currentRoute" to="/about" class="link nav__link">Sobre mí</router-link>
                        <router-link v-if="'Recursos' != currentRoute" to="/resources" class="link nav__link">Recursos</router-link>
                        <router-link v-if="'Contacto' != currentRoute" to="/contact" class="link nav__link">Contacto
                        </router-link>
                        <a href="https://blog.simonettitomas.com/" target="_blank" class="link nav__link">Blog
                            <OpenNewWindow class="newtab-icon" />
                        </a>
                    </div>

                    <button @click="toggleMobileNav" title="Abrir Menú" class="menu__icon-btn">
                        <MenuHamburger v-show="isMobile" class="menu__icon" />
                    </button>

                    

                </div>
                <!-- Mobile Nav -->
                <transition name="mobile-nav">
                        <ul v-show="mobileNav && isMobile" class="mobile-nav__links">
                            <h2>{{ currentRoute }}</h2>
                            <router-link v-if="'Inicio' != currentRoute" to="/" class="link mobile-nav__link" @click="toggleMobileNav()">Inicio</router-link>
                            <router-link v-if="'Sobre Mí' != currentRoute" to="/about" class="link mobile-nav__link" @click="toggleMobileNav()">Sobre mí</router-link>
                            <router-link v-if="'Recursos' != currentRoute" to="/resources" class="link mobile-nav__link" @click="toggleMobileNav()">Recursos</router-link>
                            <router-link v-if="'Contacto' != currentRoute" to="/contact" class="link mobile-nav__link" @click="toggleMobileNav()">Contacto</router-link>
                            <a href="https://blog.simonettitomas.com/" target="_blank" class="link mobile-nav__link" @click="toggleMobileNav()">
                                Blog
                                <OpenNewWindow class="newtab-icon" />
                            </a>
                        </ul>
                </transition>
            </nav>
            <div class="header__titles">
                <h1>SIMONETTI <span class="subtitle">TOMÁS</span></h1>
                <div class="social-links">
                    <a href="https://www.instagram.com/tomy.simonetti/" aria-label="Perfil de Instagram" target="__blank" class="link social-link">
                        <Instagram class="social-link__svg"/>
                    </a>
                    <a href="https://www.linkedin.com/in/tomas-simonetti/" aria-label="Perfil de LinkedIn" target="__blank" class="link social-link">
                        <Linkedin class="social-link__svg"/>
                    </a>
                    <a href="mailto:contacto@simonettitomas.com" aria-label="Enviar un e-mail a Tomás Simonetti" target="__blank" class="link social-link">
                        <MailOutline class="social-link__svg"/>
                    </a>
                </div>
            </div>
            <ScrollTo href="#main" :title="'Main content'">
                <ArrowDown class="header__arrow-down" />
            </ScrollTo>
        </div>
    </header>
</template>



<style lang="sass" scoped>
@use "@/style/fonts"
@use "@/style/colors"
@use "@/style/mixins"
@use "@/style/variables"
@use "@/style/animations"


header
    height: 100vh
    min-height: 550px
    position: relative
    color: colors.$white

// Background
.cover__background
    filter: brightness(0.5)
    top: 0
    left: 0
    overflow: hidden
    z-index: variables.$z-content-bellow
.cover__background--video
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    object-fit: cover
.cover__background, .cover__background--video
    width: 100%
    height: 100%
    position: absolute

// Content
.cover__content
    z-index: variables.$z-content-above
nav
    @include mixins.flex(row, space-between, center, nowrap)
    width: calc(95% - 1.4rem)
    margin-inline: auto
    padding-top: 2rem
    height: 5rem
    h2
        font-size: fonts.$font-lg
        font-weight: fonts.$font-bold
        height: 3rem
.content__right-content
    @include mixins.flex(row, flex-end, center, nowrap)
    gap: 1rem

.nav__links, h2, .social-link, .header__arrow-down
    color: colors.$white
.nav__links
    @include mixins.flex(row, center, center, nowrap)
    gap: 2rem
    @media screen and (max-width: 768px)
        justify-content: center
        padding-left: 0
    .nav__link
        font-size: fonts.$font-md
        font-weight: fonts.$font-regular
        line-height: 1rem
        padding-bottom: 11px
        border-bottom: 3px solid transparent
        transition: color variables.$transition-fast, border variables.$transition-fast
        margin-top: 0.75rem
        color: colors.$white
        &:hover
            border-bottom: 4px solid colors.getColor("accent")
    .nav__link--first
        display: block
.newtab-icon
    width: 1rem
    height: 1rem

// Mobile Nav

.mobile-nav__links
    @include mixins.flex(column, flex-start, stretch, nowrap)
    position: fixed
    top: 0
    left: 0
    z-index: variables.$z-content-above
    width: 300px
    height: 100vh
    max-width: 70vw
    padding-top: 2rem
    text-align: left
    color: colors.$white
    background-color: colors.$dark-shadow
    h2
        font-size: fonts.$font-lg
        font-weight: fonts.$font-bold
        height: 3.5rem
        margin-left: 2rem
    .mobile-nav__link
        font-size: fonts.$font-md
        padding-left: 2rem
        padding-block: 1rem
        border-right: 5px solid transparent
        transition: all variables.$transition-fast
        &:hover
            border-right: 10px solid colors.getColor("accent-dark")
            background-color: colors.$dark
.menu__icon-container
    position: relative
.menu__icon-btn
    background: transparent
    border: none
    outline: none
.menu__icon
    color: colors.$white
    width: 2rem
    height: 2rem
    cursor: pointer


.header__titles
    @include mixins.flex(column, center, center, nowrap)
    height: calc(100vh - 5rem)
    h1
        font-size: fonts.$font-xxxl
        font-weight: fonts.$font-bold
        color: colors.$white
        line-height: 1.1
        user-select: none
        -webkit-animation: tracking-in-expand 1.3s cubic-bezier(0.215, 0.610, 0.355, 1.000) both
        animation: tracking-in-expand 1.3s cubic-bezier(0.215, 0.610, 0.355, 1.000) both
    .subtitle
        font-weight: fonts.$font-light
        display: block
    .social-links
        @include mixins.flex(row, center, center, nowrap)
        gap: 1rem
        .social-link
            border: 2px solid colors.$white
            border-radius: 20px
            line-height: 0
            padding: 0.3rem
            transition: all variables.$transition-fast
            svg
                height: fonts.$font-md
                width: fonts.$font-md
                transition: all variables.$transition-fast
            &:hover
                border-color: colors.getColor("accent")
                background-color: colors.getColor("accent")
.header__arrow-down
    width: 70px
    position: absolute
    bottom: 20px
    left: calc(50% - 35px)
</style>